import React from "react"
import Layout from "@components/layout"
import Meta from "@components/meta"
import * as Heading from "@components/heading"
import BreadCrumbs from "../components/breadcrumbs";
import { StaticImage } from "gatsby-plugin-image"
import { Carousel } from 'react-responsive-carousel'
import "react-responsive-carousel/lib/styles/carousel.min.css";

const Office = () => (
  <Layout>
    <Meta
      title="本社ビル施設"
      desc="新しい可能性を追求していくには、執務環境も重要な要素だと考えています。ビル全体のイメージテーマは、近未来と光の融合…。そして、人と人とが繋がる明るい施設環境を実現しました。"
    />{ }
    <Heading.H1 text="本社ビル施設" />
    <div className="px-5 mb-12 lg:max-w-4xl lg:mx-auto">
      <BreadCrumbs
        lists={[
          {
            string: "会社情報",
            path: "/company",
          },
          {
            string: "本社ビル施設",
          }
        ]}
      />
      <p>新しい可能性を追求していくには、執務環境も重要な要素だと考えています。ビル全体のイメージテーマは、近未来と光の融合…。そして、人と人とが繋がる明るい施設環境を実現しました。</p>
      <Heading.H3 text="岡山本社" id="okayamaHQ" />
      <div className="lg:flex">
        <p className="lg:block lg:pr-10">岡山の街でもひときわ目立つ外観は、グレーとブルーを基調にしています。社内も明るく社員同士のコミュニケーションもスムーズな執務環境です。</p>
        <div className="-mx-5 my-5 p-10 bg-secondary lg:m-0 lg:p-0 lg:bg-white">
          <Carousel autoPlay="true" className="-mb-10 lg:w-96">
              <StaticImage src="../images/office_1-0.jpg" />
              <StaticImage src="../images/office_1-1.jpg" />
              <StaticImage src="../images/office_1-2.jpg" />
              <StaticImage src="../images/office_1-3.jpg" />
              <StaticImage src="../images/office_1-5.jpg" />
              <StaticImage src="../images/office_1-0.jpg" />
          </Carousel>
        </div>
      </div>
      <Heading.H3 text="地域との共生" id="local" />
      <div className="lg:flex">
        <p className="lg:block lg:pr-10">企業は1社繁栄すれば良いというのものではありません。木原興業株式会社はご近所との日々のお付き合いを何より大切にしており、近隣の清掃を行っています</p>
        <div className="-mx-5 my-5 p-10 bg-secondary lg:m-0 lg:p-0 lg:bg-white">
          <Carousel autoPlay="true" className="-mb-10 lg:w-96">
            <StaticImage src="../images/office_2-1.jpg" />
            <StaticImage src="../images/office_2-2.jpg" />
            <StaticImage src="../images/office_2-3.jpg" />
          </Carousel>
        </div>
      </div>
    </div>
  </Layout>
)

export default Office;